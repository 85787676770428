<script
    lang="ts"
    setup
>
    import AppSheetHeader from '~/components/app/sheet/AppSheetHeader.vue'

    type Props = {
        headerCloseDisabled?: boolean
        headerClass?: string
        headerRightSideClass?: string
        contentClass?: string
    }

    type Emit = {
        (event: 'close'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()
</script>

<template>
    <div
        class="
            z-10
            fixed
            top-0
            left-0
            w-full
            h-[calc(100%-var(--navbar-height))]
            flex
            flex-col
            bg-white
        "
    >
        <AppSheetHeader
            :class="props.headerClass"
            :right-side-class="props.headerRightSideClass"
            :close-disabled="props.headerCloseDisabled"
            @close="emit('close')"
        >
            <slot name="title" />

            <template #right>
                <slot name="header-right" />
            </template>
        </AppSheetHeader>

        <div :class="[ 'overflow-y-auto h-full w-full p-4', props.contentClass ]">
            <slot />
        </div>
    </div>
</template>
